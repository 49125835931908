// Assign a button to toggle a dropdown
// Accepts two selectors formatted as strings (e.g. ".class-name" or "#id-name")
const setUpDropdown = (toggleSelector, dropdownSelector, closeSelector, closeCallback, toggleCallback) => {
    const toggleButton = document.querySelector(toggleSelector);
    const closeButton = closeSelector ? document.querySelector(closeSelector) : null;
    const dropdown = document.querySelector(dropdownSelector);
    // Toggle the menu by clicking the toggle button; focusing and pressing enter also registers as a click
    toggleButton.addEventListener("click", (e) => {
        toggleDropdown(e, dropdown, toggleCallback);
    })
    // Close the menu by clicking the close button if one is provided
    if (closeButton) {
        closeButton.addEventListener("click", (e) => {
            e.preventDefault();
            closeDropdown(dropdown, closeCallback);
        })
    }
    // Close the menu after clicking anything but the toggle button
    document.addEventListener("click", (e) => {
        if (!e.target.closest(toggleSelector)) {
            closeDropdown(dropdown, closeCallback);
        }
    })
    // Close the menu by pressing "Escape"
    document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            closeDropdown(dropdown, closeCallback);
        }
    })
}

const toggleDropdown = (e, dropdown, toggleCallback) => {
    e.preventDefault();
    dropdown.classList.toggle("vb-dropdown-menu_visible");
    toggleCallback ? toggleCallback() : null;
}

const closeDropdown = (dropdown, closeCallback) => {
    dropdown.classList.remove("vb-dropdown-menu_visible");
    closeCallback ? closeCallback() : null;
}

window.setUpDropdown = setUpDropdown;