(function($) {

  $.fn.vbSortable = function(options) {
    var defaults = {
      axis: 'y',
      scroll: 'true',
      cursorAt: { top: -5 },
    };

    var setting = $.extend(defaults, options);
    setting["update"] = function() {
//      $.post("/sortable/reorder", $(this).sortable('serialize'));
      $.ajaxQueue({
      	type: "POST",
      	url: "/sortable_override/reorder",
      	data: $(this).sortable('serialize')
      });
    }

    setting["start"] = function (event, ui) {
      if (ui.item.hasClass("assignment-list-item")){
        ui.item.css("min-height","50px");
        $('ul').find('.assignment-group:last').addClass('last-group-highlight');

        var timer;
        $('.assignment-group').mouseenter(function() {
          let target = $(this).find('[data-toggle="collapse"]').attr('aria-controls')
          timer = setTimeout(function(){
            $(target).collapse('show');
            $(".assignment-sortable").sortable("refresh");
          }, 750);
        }).mouseleave(function() {
          clearTimeout(timer);
        });

      }
    }

    setting["stop"] = function (event, ui) {
      if (ui.item.hasClass("assignment-list-item")) {
      $('ul').find('.assignment-group:last').removeClass('last-group-highlight');
      $('.group-highlight').removeClass('group-highlight')
      ui.item.css("min-height", "0px");
      $('.assignment-group').off("mouseenter");
      $('.assignment-group').off("mouseleave");
      }
    }

    setting["over"] = function ( event, ui ) {
      if (ui.item.hasClass("assignment-list-item")) {
        $('.group-highlight').removeClass('group-highlight')
        $(event.target).parent().parent().addClass('group-highlight')
      }
    }

    this.sortable(setting);
  };

})(jQuery);