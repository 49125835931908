document.addEventListener('turbolinks:load', function() {
    let editActivitySettingsModal = $("#edit-activity-settings-content")
    if (editActivitySettingsModal == null) {
        return
    }

    $(".edit-activity-settings-button").on("click", function() {
        markActivityCardForEdit($(this).closest(".activity-card"))
    })

    // Marks the provided activity card that it's settings are currently being edited
    function markActivityCardForEdit(activityCard) {
        activityCard.attr("editing-settings", true)
    }
})