document.addEventListener("turbolinks:load", function() {
    let observer;

    $('.gradebook-dropdown-menu-container')
        .on('show.bs.dropdown', function(event) {
            // Get all of the required elements.
            // NOTE: This is tied directly to the structure of the dropdown menu and header now.
            const header = $(event.relatedTarget.childNodes[0]);
            const dropdown = $(event.relatedTarget.parentElement.childNodes[1]);
            const cornerArrow = $(event.relatedTarget.childNodes[0].childNodes[1]);
            const width = Math.max(dropdown.outerWidth(), header.outerWidth());

            // Resize the width of the header and dropdown menu so that they match.
            header.css("min-width", width);
            dropdown.css("min-width", width);

            // Show the header of the dropdown menu.
            header.show()

            // Create the callback for when the placement of the dropdown menu has changed.
            // When that occurs, change the arrow direction in the header accordingly.
            const observerCallback = function(mutationsList, observer) {
                for (const mutation of mutationsList) {
                    if (mutation.type == 'attributes' && mutation.attributeName == 'x-placement') {
                        const placement = mutation.target.getAttribute('x-placement');

                        // Changes the direction of the arrow in the header based on the menu alignment.
                        if (placement == "top-end") {
                            cornerArrow.css({"-webkit-transform": "scaleY(-1)", "transform": "scaleY(-1)"});
                        } else {
                            cornerArrow.css({"-webkit-transform": "scaleY(1)", "transform": "scaleY(1)"});
                        }
                    }
                }
            }

            // Create a new observer and observe the dropdown menu.
            observer = new MutationObserver(observerCallback);
            observer.observe(event.relatedTarget.parentElement.childNodes[1], {attributes: true});
        })
        .on('hide.bs.dropdown', function(event){
            // Hide the header of the dropdown menu.
            $(event.relatedTarget.childNodes[0]).hide();

            // Disconnect the observer from the dropdown menu.
            observer.disconnect();
        }
    )
})