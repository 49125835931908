document.addEventListener("turbolinks:load", function() {
    const closeButton = document.querySelector(".downtime-banner__x-close-button");
    if (closeButton !== null) {
        closeButton.addEventListener("click", closeAlert);
        closeButton.addEventListener("keyup", (e) => {
            if (e.key === "Enter") closeAlert();
        })
    }
})

function closeAlert() {
    // Grab the param and value for app and isolate the value from the remainder of the URL
    const slicedUrl = window.location.href.slice(window.location.href.indexOf("app="));
    const otherParams = slicedUrl.indexOf("&");
    const app = (otherParams === -1) ? slicedUrl.slice(4) : slicedUrl.slice(4, otherParams);
    const banner = document.querySelector(".downtime-banner");
    banner.classList.add("downtime-banner_fadeout");
    downtimeBannerClosed(app);
}

async function downtimeBannerClosed(app) {
    const response = await fetch("/downtime_banner_closed/?app=" + app);
}