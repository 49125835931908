document.addEventListener("turbolinks:load", function () {
    // Welcome Section - Launch Atlas web app or navigate to universal link depending on device
    if (document.querySelector(".welcome-section__vb-suite-icon_app")) {
        const linkSelectors = [
            ".welcome-section__vb-suite-icon_app",
            ".welcome-section__vb-suite-launch-button_app",
        ]
        linkSelectors.forEach(assignUniversalLinkHandler);
    } else if (document.querySelector(".welcome-section__vb-suite-icon_web")) {
        const launchConfigs = [
            {selector: ".welcome-section__vb-suite-icon_web", app: "Atlas"},
            {selector: ".welcome-section__vb-suite-launch-button_web", app: "Atlas"},
            {selector: ".welcome-section__dropdown-item-link_human-anatomy", app: "Atlas", customApp: "atlasHumanAnatomy"},
            {selector: ".welcome-section__dropdown-item-link_mandk", app: "Atlas", customApp: "atlasMandk"},
            {selector: ".welcome-section__dropdown-item-link_aandp", app: "Atlas", customApp: "atlasAandp"},
            {selector: ".welcome-section__dropdown-item-link_pandp", app: "Atlas", customApp: "atlasPandp"},
            {selector: ".welcome-section__dropdown-item-link_biology", app: "Atlas", customApp: "atlasBiology"},
            {selector: ".welcome-section__dropdown-item-link_home", app: "Atlas"},
        ]
        launchConfigs.forEach(assignLaunchHandler);
    }
    // Welcome Section - Set up the launch button dropdown using functionality from vb_dropdown_menu.js
    if (document.querySelector(".welcome-section__dropdown-arrow-down-link")) {
        const closeCallback = function() {
            const launchButton = document.querySelector(".welcome-section__vb-suite-launch-button");
            launchButton.classList.remove("welcome-section__vb-suite-launch-button_hidden-on-desktop");
        }
        const toggleCallback = function() {
            const launchButton = document.querySelector(".welcome-section__vb-suite-launch-button");
            launchButton.classList.toggle("welcome-section__vb-suite-launch-button_hidden-on-desktop");
        }
        setUpDropdown(
            ".welcome-section__dropdown-arrow-down-link",
            ".welcome-section__dropdown-content",
            ".welcome-section__dropdown-arrow-up-link",
            closeCallback,
            toggleCallback
        );
        // Prevent the app from opening when the dropdown arrow is selected
        const dropdownButton = document.querySelector(".welcome-section__dropdown-arrow-down-link");
        dropdownButton.addEventListener("click", (event) => {
            event.stopPropagation();
        });
    }
    // Legacy Apps Section - Assign event handlers to launch apps when elements are clicked
    if (document.querySelector(".legacy-apps")) {
        const launchConfigs = [
            {selector: ".legacy-apps__other-apps-app_a-and-p", app: "AnatomyPhysiology"},
            {selector: ".legacy-apps__other-apps-app_biology", app: "Biology"},
            {selector: ".legacy-apps__other-apps-app_muscles", app: "Muscles"},
            {selector: ".legacy-apps__other-apps-app_physanims", app: "PhysAnims"},
        ]
        launchConfigs.forEach(assignLaunchHandler);
    }
    // Launch apps when appropriate elements are clicked - launch paths are generated with Ruby in the dashboard view
    function assignLaunchHandler(launchConfig) {
        const launchPaths = document.querySelector(".dashboard__launch-paths").dataset;
        ["click", "keypress"].forEach(function(event) {
            document.querySelector(launchConfig.selector).addEventListener(event, (event) => {
                if (event.type === "keypress" && event.key !== "Enter"){
                    return;
                }
                const appKey = launchConfig.customApp || launchConfig.app.toLowerCase();
                const app = launchConfig.app;
                event.preventDefault();
                window.open(launchPaths[appKey], app, "");
            });
        });
    }
    // Navigate to subscription universal link
    function assignUniversalLinkHandler(selector) {
        document.querySelector(selector).addEventListener("click", (event) => {
            window.location.href = "https://www.visiblebody.com/applink/?p=com.visiblebody.subscription&t=qv_full_skeleton";
        });
    }
})