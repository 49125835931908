document.addEventListener('turbolinks:load', function() {
    let elementIdArray = ["ia-assignment-launch-form"]
    let shouldLoad = elementIdArray.some((elementId) => {
        return document.getElementById(elementId) != null
    })
    // Exit if we should not load the rest of this file.
    if (!shouldLoad) { return }

    let activityEditList = $("#edit-activity-list-launch")
    let editableTextElements = $(".editable-text")
    let assignmentFolderPicker = $("#assignment-folder-name-picker")
    let assignmentFolderNewName = $("#assignment-folder-new-name-field")
    let startDatePicker = $("#start-datetimepicker-edit")
    let dueDatePicker = $("#due-date-datetimepicker-edit")

    // Function for entering edit mode on the assignment show page when the user selects the edit assignment button.
    function enterEditMode() {
        let containerNames = [
            "assignment-info-container-instructor",
            "activity-list-launch",
            "assignment-description-container",
            "assignment-name-container"
        ]
        containerNames.forEach((containerName) => {
            let editContainer = $("#edit-" + containerName)
            if (editContainer.length !== 0) {
                editContainer.removeClass("hidden")
                editContainer.removeAttr("hidden")

                let container = $("#" + containerName)
                if (container.length !== 0) {
                    container.addClass("hidden")
                }
            }
        })
        $("#assignment-description-show-hide-button-container").addClass("hidden")
    }

    // Functions and event listeners for text areas
    function handleHoverState() {
        editableTextElements.hover(function () {
            $(this).addClass('editable-text-hover')
            $(this).closest("div").find(".edit-icon").removeClass('edit-pencil-icon-hide');
            $(this).closest("div").find(".edit-icon").addClass('edit-pencil-icon-show');
        }, function () {
            $(this).removeClass('editable-text-hover')
            $(this).closest("div").find(".edit-icon").removeClass('edit-pencil-icon-show');
            $(this).closest("div").find(".edit-icon").addClass('edit-pencil-icon-hide');
        })
    }

    // Function for setting up the hover functionality for editable text that would like a hover state.
    function setHoverStates() {
        handleHoverState()
        editableTextElements.hover(function () {
            $(this).closest("div").find(".edit-icon").removeClass('edit-pencil-icon-hide')
            $(this).closest("div").find(".edit-icon").addClass('edit-pencil-icon-show')
        }, function () {
            $(this).closest("div").find(".edit-icon").removeClass('edit-pencil-icon-show');
            $(this).closest("div").find(".edit-icon").addClass('edit-pencil-icon-hide');
        });
        editableTextElements.on('focus', function () {
            $(this).removeClass('editable-text-hover')
            $(this).off('mouseenter mouseleave')
        })
        editableTextElements.on('focusout', function () {
            $(this).on('mouseenter mouseleave', handleHoverState)
            $(this).closest("div").find(".edit-icon").removeClass('edit-pencil-icon-show')
            $(this).closest("div").find(".edit-icon").addClass('edit-pencil-icon-hide')
        })
        editableTextElements.on("click", function () {
            this.setSelectionRange(0, this.value.length);
        });
    }

    // Initializes the tinyMce editor for various activity elements.
    function initTinyMce() {
        activityEditList.children("li").each(function (index, element) {
            let descriptionElement = $(element).find(".activity-description")
            descriptionElement.attr("id", descriptionElement.attr("id") + "-" + index)
            // Initialize the tinyMCE edit options.
            tinymce.init({
                selector: "textarea#" + descriptionElement.attr("id"),
                plugins: 'lists',
                menubar: false,
                statusbar: false,
                browser_spellcheck: true,
                contextmenu: false,
                height: $(descriptionElement).height(),
                toolbar: 'bullist | bold italic | forecolor',
                lists_indent_on_tab: false,
                paste_as_text: true,
                formats: {
                    bold: {inline: 'b'},
                    italic: {inline: 'i'},
                },
                extended_valid_elements: "b,i",
                invalid_elements: 'em strong',
                init_instance_callback: function (editor) {
                    editor.on('focus', function (e) {
                        // Show the toolbar when gaining focus.
                        $(e.target.iframeElement
                            .closest(".tox-editor-container")
                            .querySelector(".tox-editor-header")).show()

                        // Increase the height of the description box.
                        $(e.target.iframeElement
                            .closest(".tox-tinymce")).height(300)
                    });
                    editor.on('blur', function (e) {
                        // Hide the toolbar when losing focus.
                        $(e.target.iframeElement
                            .closest(".tox-editor-container")
                            .querySelector(".tox-editor-header")).hide()

                        // Shrink the height of the description box back to its original.
                        $(e.target.iframeElement
                            .closest(".tox-tinymce"))
                            .height($(descriptionElement).height())
                    });
                },
                setup: function (editor) {
                    editor.on('init', function (e) {
                        // Hide the toolbar by default.
                        $(e.target.iframeElement
                            .closest(".tox-editor-container")
                            .querySelector(".tox-editor-header")).hide()

                        // Show the activity card data (e.g. activity title, description, etc.)
                        $(e.target.iframeElement
                            .closest(".activity-card-right")).show()
                    });
                }
            });
        });
    }

    // Listener for when the point value of an activity has changed.
    function onPointValueChanged(element) {
        // Sanitize the point value.
        if (element) {
            // If the point value is empty, set it to zero.
            if (element.value === '') {
                element.value = 0
                // If the point value is less than the defined min, set it to the min.
            } else if (element.min !== '' && parseInt(element.value) < parseInt(element.min)) {
                element.value = parseInt(element.min)
                // If the point value is greater than the defined max, set it to the max.
            } else if (element.max !== '' && parseInt(element.value) > parseInt(element.max)) {
                element.value = parseInt(element.max)
            }
        }
    }

    // Function for setting the visibility of the provided element's error container
    // based on the visibility parameter provided.
    function setErrorContainerVisibility(element, isVisible) {
        let errorID = $(element).data("error-container-id")
        if (errorID) {
            let errorContainer = $("#" + errorID + "-container")
            if (errorContainer) {
                if (isVisible) {
                    if (errorContainer.hasClass("hidden")) {
                        errorContainer.removeClass("hidden")
                    }
                } else {
                    if (!errorContainer.hasClass("hidden")) {
                        errorContainer.addClass("hidden")
                    }
                }
            }
        }
        updateButtonStatesFromErrorChange()
    }

    // Function for updating the state of various buttons based on the number of
    // error messages that are visible.
    function updateButtonStatesFromErrorChange() {
        let errorMessageCount = $(".error-message:not(.hidden)").length
        let buttonState = errorMessageCount === 0 && isFolderNameValid()
        setSaveChangesButtonState(buttonState)
        setAddActivitiesButtonState(buttonState)
    }

    // Function for setting the state of the add activities button based on the provided enabled value.
    function setAddActivitiesButtonState(enabled) {
        setButtonState("#add-activities-button-edit", enabled)
    }

    // Function for setting the state of the save changes button based on the provided enabled value.
    function setSaveChangesButtonState(enabled) {
        setButtonState("#edit-mode-save-changes-button", enabled)
    }

    // Function for setting the state of the button with the provided id based on the provided enabled value.
    function setButtonState(id, enabled) {
        let button = $(id)
        if (button) {
            if (enabled) {
                if (button.hasClass("disabled")) {
                    button.removeClass("disabled")
                }
                button.attr("disabled", false)
            } else {
                if (!button.hasClass("disabled")) {
                    button.addClass("disabled")
                }
                button.attr("disabled", true)
            }
        }
    }

    // Function for handling when the provided editable name field element loses focus.
    function editableNameFieldLostFocus(element) {
        if (element) {
            if (element.value === "" || element.value == null) {
                setErrorContainerVisibility(element, true)
            } else {
                setErrorContainerVisibility(element, false)
            }
        }
    }

    // Function for determining whether or not the current folder name, if any, is valid.
    function isFolderNameValid() {
        return !(assignmentFolderPicker.val() === "-1" &&
            (assignmentFolderNewName.val() === "" || assignmentFolderNewName.val() == null))
    }

    // Function for the drag and drop reordering of the activity list
    activityEditList.sortable({
        tolerance: 'pointer',
        opacity: 0.5,
        containment: '#edit-activity-list-launch',
        receive: function (event, ui) {},
        start: function(event, ui) {
            ui.placeholder.css({visibility: 'visible', border: '2px dashed #a7a9ac'})
            $(this).find('.activity-description').each(function(){
                let editor = tinymce.get($(this).attr('id'));
                editor.remove();
            });
        },
        stop: function (event, ui) {
            initTinyMce();
        },
        over: function (event, ui) {},
        out: function (event, ui) {}
    });

    // Functionality for reordering activities by drag-and-drop
    activityEditList.droppable({
        over: function(e, ui) {},
        out: function (event, ui) {},
        drop: function (event, ui) {}
    });

    // Add callback for when the edit button has been clicked.
    $("#edit-mode-action-button").on("click", enterEditMode)

    // Add listener for when the activity point value field has changed.
    $(".activity-points-field").change(function() {
        onPointValueChanged(this)
    })

    // Function that does the setup for the read more/show less functionality for the assignment description.
    $(".read-more-less-container").each(function(index, element) {
        let description = $(element).find(".read-more-less-description")
        let button = $(element).find(".read-more-less-button")
        if (description.get(0) && button.get(0)) {
            let lineHeight = parseInt(window.getComputedStyle(description.get(0)).lineHeight)
            let numberOfLines = $(description).data("max-lines")
            // If there isn't a max number of lines set, default to 3
            if (!numberOfLines) {
                numberOfLines = 3
            }
            $(description).css("max-height", numberOfLines * lineHeight)

            // Check if the text exceeds the max line count
            if (description.prop("scrollHeight") > numberOfLines * lineHeight) {
                let buttonContainer = $(element).find(".description-show-hide-button-container")
                buttonContainer.css("display", "flex")
                button.css("display", "block")
            }

            button.on("click", function() {
                if (description.hasClass("read-more-less-expanded")) {
                    button.text("Read More...")
                    description.removeClass("read-more-less-expanded")
                    description.addClass("read-more-less-collapsed")
                } else {
                    button.text("Show Less...")
                    description.removeClass("read-more-less-collapsed")
                    description.addClass("read-more-less-expanded")
                }
                description.scrollTop(0)
            })
        }
    })

    // Handles showing/hiding the assignment name field error.
    $("#assignment-edit-name-field").on({
        "blur": function() {
            editableNameFieldLostFocus(this)
        }
    })

    // Handles showing/hiding the activity name field error.
    $(".activity-edit-name-field").on({
        "blur": function() {
            editableNameFieldLostFocus(this)
        }
    })

    // Handles showing/hiding the folder group name field error.
    assignmentFolderNewName.on({
        "blur": function() {
            editableNameFieldLostFocus(this)
        }
    })

    // Handles functionality for when an assignment folder option is selected.
    assignmentFolderPicker.on({
        "change": function() {
            if (this.value === "-1") {
                // This means we have selected the new folder option.
                // We want to disable the save changes button if there isn't a valid name already entered.
                if (assignmentFolderNewName.val() === "" || assignmentFolderNewName.val() == null) {
                    setSaveChangesButtonState(false)
                    setAddActivitiesButtonState(false)
                }
            } else {
                // Otherwise, we selected an existing folder option so we should clear any
                // lingering error messages for the new folder name.
                setErrorContainerVisibility(assignmentFolderNewName, false)
            }
        }
    })

    // Handles functionality for picking the start date of the assignment.
    startDatePicker.on({
        "change": function() {
            let startDateTime = new Date(this.value)
            let dueDateTime = new Date(dueDatePicker.val())
            dueDatePicker.flatpickr({
                enableTime: true,
                dateFormat: "Y-m-d h:i K",
                minDate: startDateTime
            })
            setErrorContainerVisibility(this, dueDateTime <= startDateTime)
            if (startDateTime < dueDateTime) {
                setErrorContainerVisibility(dueDatePicker.get(0), false)
            }
        }
    })

    // Handles functionality for picking the due date of the assignment.
    dueDatePicker.on({
        "change": function() {
            let currentTime = new Date()
            let startDateTime = new Date(startDatePicker.val())
            let dueDateTime = new Date(this.value)
            setErrorContainerVisibility(this, dueDateTime <= startDateTime || dueDateTime < currentTime)
            if (dueDateTime > startDateTime) {
                setErrorContainerVisibility(startDatePicker.get(0), false)
            }
        }
    })

    // Function for retrieving the relevant assignment edit data before proceeding
    // to the IA builder page.
    function retrieveEditInfo() {
        tinyMCE.triggerSave();
        let editInfo = {}
        let editNameField = $("#assignment-edit-name-field")
        if (editNameField) {
            editInfo["assignment_name"] = editNameField.val()
        }
        let editDescriptionField = $("#assignment-edit-description-field")
        if (editDescriptionField) {
            editInfo["description"] = editDescriptionField.val()
        }
        let editStartDate = $("#start-datetimepicker-edit")
        if (editStartDate) {
            editInfo["start_date"] = editStartDate.val()
        }
        let editDueDate = $("#due-date-datetimepicker-edit")
        if (editDueDate) {
            editInfo["due_date"] = editDueDate.val()
        }
        let folderNamePicker = $("#assignment-folder-name-picker")
        if (folderNamePicker) {
            editInfo["assignment_group_id"] = folderNamePicker.val()
        }
        let folderNameField = $("#assignment-folder-new-name-field")
        if (folderNameField) {
            editInfo["assignment_group_name"] = folderNameField.val()
        }
        editInfo["activities"] = []

        // Grab the important information from each activity
        $(".activity-card-edit").each(function (index, card) {
            let activityData = {}
            let instructions = $(card).find('.activity-description').val()
            if (instructions != null && instructions !== "") {
                activityData["instructions"] = instructions
            }
            let name = $(card).find('.activity-title').val()
            if (name != null && name !== "") {
                activityData["name"] = name
            }
            activityData["points"] = $(card).find('.activity-points').val()
            activityData["id"] = $(card).data("activity-id")
            editInfo["activities"].push(activityData)
        })
        return editInfo
    }

    // Handles the clicking on the add activities button while in edit mode.
    // Data needs to be saved before leaving the page.
    $("#add-activities-button-edit").on("click", function() {
        let editInfo = retrieveEditInfo()
        $.ajax({
            url: "/assignment/set_edit_data",
            type: "POST",
            dataType: "JSON",
            data: {
                assignment_name: editInfo["assignment_name"],
                description: editInfo["description"],
                start_date: editInfo["start_date"],
                due_date: editInfo["due_date"],
                assignment_group_id: editInfo["assignment_group_id"],
                assignment_group_name: editInfo["assignment_group_name"],
                activities: editInfo["activities"]
            },
            success: function(response) {
                $("#add-activities-link-to-edit").get(0).click()
            }
        });
    })

    // Handles the clicking on the add activities button while in the show mode.
    // Data needs to be saved before leaving the page.
    $("#add-activities-button-show").on("click", function() {
        let assignmentID = $("#ia-assignment-launch-form").data("assignment-id")
        $.ajax({
            url: "/assignment/set_edit_data",
            type: "POST",
            dataType: "JSON",
            data: {
                assignment_id: assignmentID
            },
            success: function(response) {
                $("#add-activities-link-to-edit").get(0).click()
            }
        });
    })

    setHoverStates();
    initTinyMce();
})