document.addEventListener("turbolinks:load", function() {
    if (document.querySelector(".welcome-section__mobile-app-stores")) {
        const dataCheck = document.querySelector(".mobile-app-modal__user-data");
        // Set up get mobile app modal using functionality from vb_modal.js
        if (dataCheck.dataset.mobile !== "true" && dataCheck.dataset.tablet !== "true") {
            setUpModal(".welcome-section__share-link", ".mobile-app-modal");
        }
        // Change App Store and Play Store image links to modal triggers if user has a placeholder password
        if (dataCheck.dataset.placeholder === "true") {
            setUpModal(".welcome-section__app-store", ".mobile-app-modal");
            setUpModal(".welcome-section__play-store", ".mobile-app-modal");
        }
        // Setup close functionality for modal popup cancel button
        document.querySelector(".mobile-app-modal__cancel-button").addEventListener("click", (e) => {
            document.querySelector(".mobile-app-modal").classList.remove("vb-modal_visible");
        })
    }
})