// This file is used to set the thumbnail of a piece of custom content.
document.addEventListener("turbolinks:load", function ()
{
    // Gather all elements that have a content link data attribute.
    let customContentThumbnailImages = document.querySelectorAll('[data-content-link]');

    // Loop through all elements that have a content link associated with them.
    for (let i = 0; i < customContentThumbnailImages.length; i++) {
        try {
            let imgElement = customContentThumbnailImages[i];

            // Make a request for the thumbnail data on the custom content with the associated content link.
            Rails.ajax({
                url: "/retrieve_custom_content_thumbnail_data/" + imgElement.dataset.contentLink,
                type: "get",
                dataType: "JSON",
                success: function(response) {
                    // Assign the source of the image to the retrieved thumbnail data.
                    imgElement.src = "data:image/jpeg;base64," + response['thumbnail_data'];
                }
            });
        } catch(e) {
            console.log("Error while attempting to generate custom content thumbnails.\n" + e.message);
        }
    }
});