document.addEventListener('turbolinks:load', function() {
    // Get search session value
    const session_search = sessionStorage.getItem("search")
    const search_origin = sessionStorage.getItem("searchOrigin")

    // If we have a session
    if (session_search) {
        const current_location = location.pathname
        let populate_search = true
        if (current_location !== "/admin/manage_roles_and_permissions" && search_origin === "live-search-input-roles") {
            // Remove the session as we no longer need it
            sessionStorage.removeItem("search");
            sessionStorage.removeItem("searchOrigin");
            populate_search = false
        }
        if (current_location !== "/admin/manage_courses" && search_origin === "live-search-input-institution-courses") {
            // Remove the session as we no longer need it
            sessionStorage.removeItem("search");
            sessionStorage.removeItem("searchOrigin");
            populate_search = false
        }
        // Set input field value to the session value
        if (populate_search)
            $("#live-search-input-field").val(session_search)

        // Run display functionality (will hide and show <tr>'s that do not match input value and re-do bootstrap striping
        display_live_search_table()
    }

    $('#live-search-input-field').on('keyup change', function(e) {
        // Run display functionality (will hide and show <tr>'s that do not match input value and re-do bootstrap striping
        // Declare searchField variable which is the input value
        let searchField = display_live_search_table()
        // Set a session searchField which is useful for duplication, edit, and deletion. Keeps the search session
        sessionStorage.setItem("search", searchField);
        sessionStorage.setItem("searchOrigin", $('#live-search-input-field').attr('name'));
    });
})

// Declare display function
var display_live_search_table = function(){
    // Search field will equal the value of input field
    let searchField = $("#live-search-input-field").val().toLowerCase()

    $(".live_search_option").each(function () { // This will be the span with the role name
        // Filter out table rows if their text does not include what the user has typed
        if ($(this).text().toLowerCase().includes(searchField)) {
            $(this).closest("tr").show()
        } else {
            $(this).closest("tr").hide()
        }
    });

    // Re-do bootstrap table striping
    $(".table-body-container tr:visible").each(function (index) {
        $(this).css("background-color", !!(index & 1) ? "rgba(0,0,0,0)": "rgba(0,0,0,.05)");
    });
    // Return value of input field declared above. Useful for session.
    return searchField
}
