document.addEventListener("turbolinks:load", function () {
    // Do not display the sign out button when within an iframe
    if (window.location !== window.parent.location) {
        // Visible Body Suite and myaccount sign out button
        const newSignOutButton = document.querySelector(".main-nav__item-link_signout");
        if (newSignOutButton) newSignOutButton.style.display = "none";
        // Courseware sign out button
        const signOutButton = document.querySelector('#header-bar .dropdown-item[data-method="delete"]');
        if (signOutButton) signOutButton.style.display = "none";
    }
});