// To use truncation - add truncation class to the parent element,
const executeTruncationCode = () => {
    const truncatedElement =  $('.truncated')
    truncatedElement.on('mouseenter', function () {
        let $this = $(this);

        if (this.offsetWidth < this.scrollWidth) {
            $this.attr('title', $this.children().text().trim());
            $this.attr('data-toggle', "tooltip");
            $this.attr('data-placement', "top");
            $this.attr('data-original-title', $this.children().text().trim());

            $this.tooltip('show')
        } else {
            $this.removeAttr('title');
            $this.removeAttr('data-toggle');
            $this.removeAttr('data-placement');
            $this.removeAttr('data-original-title');

            $this.tooltip("dispose")
        }
    });

    // truncatedElement.on('mouseout', function (){
    //     let $this = $(this);
    //
    //     $this.tooltip("hide");
    // })
}

document.addEventListener('turbolinks:load', function() {
    executeTruncationCode()
})

window.executeTruncationCode = executeTruncationCode;