// Which Gradebook view is the scroll position from? (single/multi/multi2). Is unique between pages in multi-gradebook
let currentPath = "";

// We are storing the path and page number (single/multi/multi2/etc.), left & top scroll positions in one cookie as such:
// "scrollPosition = path|left|top"

// Store scroll position of Gradebook in cookie
function getScrollPosition()
{
    let element = document.getElementById("scrolling-gradebook");
    // console.log("Scroll Position: " + element.scrollLeft + ", " + element.scrollTop);
    setScrollCookie(element.scrollLeft, element.scrollTop);
}

// Set the cookie as "scrollPosition = path|left|top"
function setScrollCookie(leftPos, topPos)
{
    let cookieString = "scrollPosition = " + currentPath + "|" + leftPos + "|" + topPos;
    // console.log("New cookie string =  " + cookieString);
    document.cookie = cookieString;
}

// Sets the current path based on which Gradebook we are viewing (single/multi/multi2...)
function setCurrentPath()
{
    // Gets whole url with parameters
    let url = window.location.href;
    // console.log("URL = " + url);

    // Check if we're in the Gradebook
    if(url.includes("grades"))
    {
        if(url.includes("show_user"))
        {
            // We're looking at the single Gradebook
            currentPath = "single";
        } else
        {
            // Multi Gradebook view
            currentPath = "multi";
            if(url.includes("page"))
            {
                currentPath += url.split("page=")[1];
            }
        }
        // console.log("Current Path set to: " + currentPath);
    } else
    {
        // console.log("We are not on a gradebook page, let's clear scrolling cookies");
        clearScrollCookie();
        return;
    }

    // Check cookies for scroll values
   let values = getValuesFromCookie();
   if(values == "")
   {
       // console.log("cookies/value not found");
       return;
   } else
   {
       // Set scroll position to the cookie values
       setScrollPosition(values);
   }
}

function setScrollPosition(values)
{
    // Array is path|left|top
    let valueArray = values.split('|');
    let path = valueArray[0];
    let leftPos = parseInt(valueArray[1]);
    let topPos = parseInt(valueArray[2]);

    // console.log("path = " + currentPath);

    // Are the saved cookies from the current path?
    if(path == currentPath)
    {
        // We have scroll values for this path, set to previous scroll position
        let element = document.getElementById("scrolling-gradebook");
        element.scrollLeft = leftPos;
        element.scrollTop = topPos;
    } else
    {
        // console.log("We do NOT have position cookies for the current path");
        // Clear cookies
        clearScrollCookie();
    }
}

// Reset the cookie
function clearScrollCookie()
{
    document.cookie = "scrollPosition = ";
}

// Returns a string of "path|left|top"
function getValuesFromCookie()
{
    let cookiesArray = document.cookie.split(';');
    // console.log("Cookie Array = " + cookiesArray);
    for(let i = 0; i < cookiesArray.length; i++)
    {
        // Find our scrollPosition cookie
        if(cookiesArray[i].includes("scrollPosition"))
        {
            // console.log("Cookie - " + cookiesArray[i]);
            // Just return the values (part after '=' sign). Will return empty string if nothing's after '=' sign
            return cookiesArray[i].split("=")[1];
        }
    }
    return "";
}

// Useful for Debugging. Add a button on an HTML page that calls this function for easy use.
function showCookies()
{
    console.log("Cookie string = " + document.cookie);
}

// This runs on page load
document.addEventListener("turbolinks:load", function ()
{
    // Set our current path
    setCurrentPath();
    // Set scroll event listener
    $("#scrolling-gradebook").scroll(function() {
        getScrollPosition();
    });
});
