document.addEventListener('turbolinks:load', function() {
    // Do not execute any of this code if we are not on the IA builder page.
    if (document.getElementById("ia-builder-container") == null) {
        return
    }

    // Array of words we're searching for
    let searchWordsArray = []
    // For determining how many assets we have of each type
    let foundAssetsOfType = 0
    let assetType = ""
    let currentAssetType = ""

    let filterData = {
        topics: [],
        combos: [],
        regions: [],
        search_words: []
    }

    $.ajax({
        url : "/ia/generate_search_data",
        type: "POST",
        dataType: "json",
        success: function(data){
            // Assign search data to all the assets on the page.
            for (let i = 0; i < data.response.assets.length; i++) {
                let asset = data.response.assets[i]
                let assetElement = document.getElementById("activity-card-" + asset["id"])

                if (assetElement != null) {
                    if (assetElement.dataset != null) {
                        assetElement.dataset.searchTerms = JSON.stringify(asset.search_terms)
                        assetElement.dataset.topics = JSON.stringify(asset.topics)
                        assetElement.dataset.combos = JSON.stringify(asset.combos)
                        assetElement.dataset.regions = JSON.stringify(asset.regions)
                    }
                }
            }

            let regionDropdownMenu = $("#ia-region-dropdown-menu")
            getSortedKeys(data["response"]["regions"], "name").forEach((key) => {
                regionDropdownMenu.append($.parseHTML(data["response"]["regions"][key]["html"]))
            })

            let topicDropdownMenu = $("#ia-topic-dropdown-menu")
            let sortedCategories = Object.keys(data["response"]["topics"])
            sortedCategories.sort(function(a, b) {
                if (a === "none") {
                    return 1
                } else if (b === "none") {
                    return -1
                }

                if (a > b) {
                    return 1
                } else if (a < b) {
                    return -1
                }
                return 0
            })
            sortedCategories.forEach((category) => {
                // Add the category as an option.
                if (category !== "none") {
                    topicDropdownMenu.append($.parseHTML(data["response"]["topics"][category]["html"]))
                }
                // Add all of the topics under this category.
                getSortedKeys(data["response"]["topics"][category]["topics"], "name").forEach((key) => {
                    topicDropdownMenu.append($.parseHTML(data["response"]["topics"][category]["topics"][key]["html"]))
                })
                // Add a category divider line, if needed.
                if (data["response"]["topics"][category].hasOwnProperty("divider_html")) {
                    topicDropdownMenu.append($.parseHTML(data["response"]["topics"][category]["divider_html"]))
                }
            })

            let subtopicDropdownMenu = $("#ia-subtopic-dropdown-menu")
            getSortedKeys(data["response"]["subtopics"], "name").forEach((key) => {
                subtopicDropdownMenu.append($.parseHTML(data["response"]["subtopics"][key]["html"]))
            })

            let typeDropdownMenu = $("#ia-type-dropdown-menu")
            getSortedKeys(data["response"]["types"], "name").forEach((key) => {
                typeDropdownMenu.append($.parseHTML(data["response"]["types"][key]["html"]))
            })

            setupEvents()
            // The filter tag event function is located within the "_link_immersive_assignment_form.html.erb"
            // because we are going to be generating the tags dynamically.
            setupFilterTagEvents()
            $("#ia-builder-loading-spinner").fadeOut()

            // Call the update assignment grade display function here because we can assume everything has loaded
            // and we can now determine if the activities in the list have point values to calculate.
            update_assignment_point_display()
        }
    });

    // Function that returns sorted keys of the provided dictionary based on the sortKey
    // provided. The values of the provided dictionary keys are also dictionaries. The sortKey
    // references a key within those dictionaries that is used to determine the sort order.
    const getSortedKeys = (filterDict, sortKey) => {
        return Object.keys(filterDict).sort(function(a, b) {
            if (filterDict[a][sortKey] > filterDict[b][sortKey]) {
                return 1
            } else if (filterDict[a][sortKey] < filterDict[b][sortKey]) {
                return -1
            }
            return 0
        })
    }

    const intersectingCombos = (selectedTopicsQuery, selectedSubtopicsQuery) => {
        const selectedTopicCombos = [].concat.apply([], selectedTopicsQuery
            .toArray()
            .map(option => $(option).data('combos')))

        const selectedSubtopicCombos = [].concat.apply([], selectedSubtopicsQuery
            .toArray()
            .map(option => $(option).data('combos')))

        return selectedTopicCombos.filter(combo => selectedSubtopicCombos.includes(combo))
    }

    const selectedIds = (selectedFilterQuery) => {
        return selectedFilterQuery
            .toArray()
            .map(option => $(option).data('id'))
    }

    const emptyFilterData = () => {
        let isEmpty = true
        Object.keys(filterData).forEach(function(key) {
            if (filterData[key].length > 0) {
                isEmpty = false
                return true
            }
        })
        return isEmpty
    }

    const filterAssets = () => {
        const emptyDataSet = emptyFilterData()

        let resultsCount = {}

        $(".ia_search_option").each(function () {
            const asset = $(this)
            const assetType = asset.data('asset-type')
            if (!(assetType in resultsCount)) {
                resultsCount[assetType] = 0
            }

            if (emptyDataSet || shouldShowAsset(asset)) {
                asset.show()
                resultsCount[assetType]++
            } else {
                asset.hide()
            }
        })

        // Update the results text and data attribute for each asset container.
        Object.keys(resultsCount).forEach((key) => {
            let resultsText = $("#" + key + "-results-text")
            resultsText.text("(" + resultsCount[key] + " " +
                (resultsCount[key] == 1 ? "result" : "results") + ")")
        })
    }

    // Determines whether or not a specific asset should be shown on the page.
    const shouldShowAsset = (asset) => {
        let shouldShow = true
        // Check if we have any combos selected. If we do, these take priority over topics.
        if (filterData.combos.length > 0) {
            shouldShow = asset.data('combos')
                .some(combo => filterData.combos.indexOf(combo) >= 0)
            if (!shouldShow) {
                return false
            }
        // Otherwise check if any topics are selected.
        } else if (filterData.topics.length > 0) {
            shouldShow = asset.data('topics')
                .some(topic => filterData.topics.indexOf(topic) >= 0)
            if (!shouldShow) {
                return false
            }
        }

        // Check if any regions are selected.
        if (filterData.regions.length > 0) {
            shouldShow = asset.data('regions')
                .some(region => filterData.regions.indexOf(region) >= 0)
            if (!shouldShow) {
                return false
            }
        }

        // Check if any search words are entered.
        let isFound = true
        if (filterData.search_words.length > 0) {
            // Grab the list of the asset's search terms, joined into one string
            let assetSearchTerms = asset.data("search-terms").join(' ').toLowerCase()
            // Loop through all user search words and check that everything is present in this
            // asset's search term list.
            shouldShow = filterData.search_words.every(searchWord => {
                if(!assetSearchTerms.includes(searchWord)) {
                    isFound = false
                }
                // Loop will break once this returns false
                return isFound
            })
            if (!shouldShow) {
                return false
            }
        }

        return true
    }

    const showClearIcon = () => {
        $("#ia-search-submit").hide()
        $("#ia-search-reset").show()
    };

    const showSearchIcon = function() {
        $("#ia-search-submit").show()
        $("#ia-search-reset").hide()
    };

    const setupEvents = function() {
        // Event that handles when a filter option is toggled.
        $('.ia-filter-checkbox').change(function(e) {
            const checkbox_option = $(this)
            handleFilterOptionSelected(checkbox_option.data("type"))

            const headerType = checkbox_option.data('header')
            if (headerType != null) {
                const header = $('#filter-option-checkbox-header-' + headerType)
                const checkedOptions = $("#ia-topic-dropdown-menu .ia-filter-checkbox[data-header=" + headerType + "]:checked")
                const totalOptions = $("#ia-topic-dropdown-menu .ia-filter-checkbox[data-header=" + headerType + "]")
                header.prop("checked", checkedOptions.length === totalOptions.length)
                handleFilterTagForCheckbox(header)
            }
        })

        $('.ia-header-filter-checkbox').change(function(e) {
            const checkboxOption = $(this)
            const headerId = checkboxOption.data('id')
            const headerChecked = checkboxOption.is(":checked")

            let topicsToChange
            if (headerChecked) {
                topicsToChange = $("#ia-topic-dropdown-menu .ia-filter-checkbox[data-header=" + headerId + "]").not(":checked")
            } else {
                topicsToChange = $("#ia-topic-dropdown-menu .ia-filter-checkbox[data-header=" + headerId + "]:checked")
            }

            topicsToChange.each((index) => {
                const uncheckedTopic = $(topicsToChange[index])
                uncheckedTopic.prop("checked", headerChecked)
                handleFilterTagForCheckbox(uncheckedTopic)
            })
            handleFilterOptionSelected("topic")
        })

        // Fire search function when typing into the search bar
        $('#ia-live-search-input-field').on('keyup', function(e) {
            // searchString will equal the value of input field. This makes it lower case and removes all punctuation
            let searchString = $("#ia-live-search-input-field").val().toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ")

            // Get array of all words we want to search for
            searchWordsArray = searchString.split(" ")

            // Combines the topic array with the search words array and sends to our search function
            // Storing it in a variable for session purposes
            filterData.search_words = searchWordsArray

            filterAssets()

            if(searchString.length > 0) {
                // Switch to X icon
                showClearIcon()
            } else {
                // Show magnifying glass icon
                showSearchIcon()
            }
            // Set a session searchPhrases which is useful for duplication, edit, and deletion. Keeps the search session
            // sessionStorage.setItem("ia-search", searchPhrases);
        });

        // When the clear button is pressed, clear the text field and display the search results so that we can reset the contents of the page
        $("#ia-search-reset").on("click", function(e) {
            $("#ia-live-search-input-field").val("")

            filterData.search_words = []
            filterAssets()

            // Show magnifying glass icon
            showSearchIcon()
        });

        // Handles the toggling on the "Show Filters" and "Hide Filters" text when clicking on the
        // toggle filters button.
        $("#toggle-filters-button").on("click", (element) => {
            // Check to see if the bottom region is already animating from the filter toggle.
            // If it is, we want to exit early and not change the button text.
            const animating = document.getElementById("filter-bottom-region").classList.contains('collapsing');
            if (animating) {
                return;
            }

            // Check the current state of the dropdown based on the aria field on the filter toggle button.
            const expanded = element.currentTarget.getAttribute('aria-expanded') === "true"
            for (child of element.currentTarget.children) {
                if ((child.id === "toggle-filters-show-text" && expanded) ||
                    (child.id === "toggle-filters-hide-text" && !expanded)) {
                    $(child).show()
                } else {
                    $(child).hide()
                }
            }
        })
    }

    const handleFilterOptionSelected = (type) => {
        const selectedTopicsQuery = $('#ia-topic-dropdown-menu :checked').not('.ia-header-filter-checkbox')
        const selectedSubtopicsQuery = $('#ia-subtopic-dropdown-menu :checked')
        const selectedRegionsQuery = $('#ia-region-dropdown-menu :checked')

        switch(type) {
            // We selected a topic.
            case "topic":
                // Check if we have any topics selected and set the subtopic dropdown state accordingly.
                const selected_topics = selectedTopicsQuery
                    .toArray()
                    .map(option => $(option).data('id'))
                const subtopicDropdownButton = $('#ia-subtopic-filter-button')[0]

                // There are now no topics selected.
                // We need to disable the subtopic button and uncheck any checked subtopics.
                if (selected_topics.length <= 0) {
                    subtopicDropdownButton.disabled = true
                    selectedSubtopicsQuery.each(function () {
                        $(this).prop("checked", false).trigger("change")
                    })
                    // We have some selected topics.
                    // We need to enable the subtopic button and reveal all related subtopics.
                } else {
                    subtopicDropdownButton.disabled = false

                    const selectedCombos = [].concat.apply([], selectedTopicsQuery
                        .toArray()
                        .map(option => $(option).data('combos')))

                    $('#ia-subtopic-dropdown-menu').children().each(function () {
                        const subtopic = $(this)
                        const subtopicCheckbox = subtopic.find('input')
                        const shouldEnable = subtopicCheckbox.data('combos')
                            .some(combo => selectedCombos.indexOf(combo) >= 0)
                        if (shouldEnable) {
                            subtopic.show()
                        } else {
                            subtopicCheckbox.prop("checked", false).trigger("change")
                            subtopic.hide()
                        }
                    })

                    // Set the array of selected topics as the topic filter data.
                    filterData.topics = selected_topics
                }
                break;

            // We selected a subtopic.
            case "subtopic":
                break;

            // We selected a region.
            case "region":
                break;

            // We selected a type.
            case "type":
                let noTypesSelected = true
                let typeKeys = $('.ia-type-filter-option')
                    .toArray()
                    .map(option => {
                        if ($(option)[0].checked) {
                            noTypesSelected = false
                        }
                        return ({ asset_type: $(option).data('key'), checked: $(option)[0].checked })
                    })

                // Update the asset type container visibility.
                Object.keys(typeKeys).forEach((key) => {
                    let assetTypeContainer = $('#' + typeKeys[key].asset_type + "-section")
                    if (noTypesSelected || typeKeys[key].checked) {
                        assetTypeContainer.show()
                    } else {
                        assetTypeContainer.hide()
                    }
                })
                break;
        }

        // Final check for all selected topics.
        filterData.topics = selectedIds(selectedTopicsQuery)

        // Final check for all selected subtopics.
        filterData.combos = intersectingCombos(selectedTopicsQuery, selectedSubtopicsQuery)

        // Final check for all selected regions.
        filterData.regions = selectedIds(selectedRegionsQuery)

        filterAssets()
    }
});
