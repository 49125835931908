// Check _whats_new.html.erb for an example
const executeNavBarScrollCode = (navBarContainer, overallScrollSection, overallScrollContainer) => {
    const navBarAnchors = navBarContainer.find("a");
    const overallScrollSections = overallScrollSection.find(".scroll-nav__section");

    let currentHighlightId;
    let prevHighlightId;
    let clickHappened = false;
    let timer;

    let refresh = function () {
        clickHappened = false
    };

    const isInViewport = (element) => {
        const top_of_element = element.offset().top;
        const bottom_of_element = element.offset().top + element.outerHeight() - 50;
        const bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        const top_of_screen = $(window).scrollTop();

        return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)
    };

    overallScrollContainer.scroll(function (event) {
        if (!clickHappened) {
            prevHighlightId = currentHighlightId
            const {scrollHeight, scrollTop, clientHeight} = event.target;

            let cur = overallScrollSections.map(function (index) {
                if (isInViewport($(this))) {
                    return this
                }
            })

            if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
                cur = cur[cur.length - 1];
            } else {
                cur = cur[0]
            }

            const h2Element = $(cur).find(">:first-child")[0]
            if ((h2Element ? $(h2Element)[0].id : "") !== "") {
                currentHighlightId = h2Element ? $(h2Element)[0].id : ""
            }

            if (prevHighlightId !== currentHighlightId) {
                navBarAnchors.removeClass("active activeSideBar")
            }

            navBarAnchors.filter("[href='#" + currentHighlightId + "']").addClass("active activeSideBar");
        }

        clearTimeout(timer);
        timer = setTimeout(refresh, 150);
    });

    $(".scrollTo").on('click', function (e) {
        e.preventDefault();
        clickHappened = true
        currentHighlightId = $(this).data("itemtoscrollto")

        document.getElementById(currentHighlightId).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });

        navBarAnchors.removeClass("active activeSideBar")
        navBarAnchors.filter("[href='#" + currentHighlightId + "']").addClass("active activeSideBar");
    });
}

window.executeNavBarScrollCode = executeNavBarScrollCode;