// Assign a button to open a modal. The button can be anywhere, but the modal should be at the bottom of the page
// Accepts two selectors formatted as strings (e.g. ".class-name" or "#id-name")
const setUpModal = (openSelector, modalSelector) => {
    const openButton = document.querySelector(openSelector);
    const modal = document.querySelector(modalSelector);
    const closeButton = modal.querySelector(".vb-modal__x-close-button");
    // Open the modal by clicking the open button; focusing and pressing enter also registers as a click for anchor tags
    openButton.addEventListener("click", (e) => {
        openModal(e, modal, closeButton);
    })
    openButton.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
            openModal(e, modal, closeButton);
        }
    })
    // Close the modal by clicking outside the content of the modal
    modal.addEventListener("click", (e) => {
        if (e.target.classList.contains("vb-modal")) {
            closeModal(modal);
        }
    })
    // Close the modal by pressing "Escape"
    document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            closeModal(modal);
        }
    })
    // Close the modal by pressing the "X" button
    closeButton.addEventListener("click", (e) => {
        closeModal(modal);
    })
}

const openModal = (e, modal, closeButton) => {
    e.preventDefault();
    modal.classList.add("vb-modal_visible");
    closeButton.focus();
}

const closeModal = (modal) => {
    modal.classList.remove("vb-modal_visible");
}

window.setUpModal = setUpModal;