$(document).on('turbolinks:load', function() {
    let navExists = document.getElementById("site-left-bar-navigation");
    let skipToContentBox = document.getElementById("skip-to-content-box");
    let navSkipLink = document.getElementById("navigation-skip-link");
    let navExistsFlag;

    if (navExists === null)
        navExistsFlag = false;
    else
        navExistsFlag = true;

    if (navExistsFlag) {
        navSkipLink.style.display = "list-item";
        skipToContentBox.style.height = "83px";
    }
    else if(navSkipLink){
        navSkipLink.style.display = "none";
        skipToContentBox.style.height = "70px";
    }
});

// this allows us to create a new focusable selector
jQuery.extend($.expr.pseudos, {
    focusable: function(el){
        return $(el).is('a[href], [tabindex]') && !$(el).is(':hidden');
    }
});

function applyFocus($target) {
    if ($target.length !== 1) {
        return;
    }
    if (!$target.is(':focusable')) {
        // add tabindex to make focusable and remove once no longer focused
        $target.attr('tabindex', -1).on('blur focusout', function () {
            $(this).removeAttr('tabindex');
        });
    }
    // triggers the focus event on target element
    $target.focus();
}

$(function(){
    // check if '#' in the URL
    if (document.location.hash) {
        applyFocus($(document.location.hash));
    }

    // if content after '#' has been changed
    $(window).bind('hashchange', function() {
        applyFocus($(window.location.hash));
    });
});