/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


/**
 * Refers to app/javascript/stylesheets/application.scss (or application.css)
 * Note that we don't need to preface this path with "app/javascript" due to the `source_path` config set in config/webpacker.yml. Magical!
 * The file extension can be left off due to the `extensions` config in config/webpacker.yml.
 */
import "stylesheets/application"
// require("@rails/ujs").start()
import Rails from "@rails/ujs"
window.Rails = Rails;
Rails.start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
window.$ = $;
require("jquery-ui")
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/disable-selection')
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require("packs/keyboard_navigation")
import 'bootstrap'
import "flatpickr";
import "select2";
// import "@ttskch/select2-bootstrap4-theme";

document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })

    $(".modal").on('hidden.bs.modal', function(){
        // TODO refocus on element that opened modal?
    });

    // When modal opens, set focus to button that dismisses it.
    // Requires delay due to modal fade-in time
    $(document).on('show.bs.modal', function(){
        addInputPrependListener();
        setTimeout(targetModalDismiss, 500, $(this));
    });

    addInputPrependListener();

    function targetModalDismiss(modal){
        modal.find("[data-dismiss='modal']").focus();
    }
})

require("rails_sortable_override")
require("gradebook_scrolling_handler")
require("UnityProgress")
require("dropdown_menu_helper")
require("live_search_display")
require("ia_content_search")
require("truncation")
require("set_custom_content_thumbnail")
require("assignment_flow_helper")
require("edit_activity_settings_helper")
// Require all js files in modules and views folders
require("modules/index")
require("views/index")

import "../vendor/jQuery-Plugin-For-Multi-Selectable-Tree-Structure-Tree-Multiselect/dist/jquery.tree-multiselect.js.erb"
import { ajaxQueue} from "../vendor/jquery-ajaxQueue/jquery.ajaxQueue.min"

// Load all of our coffee scripts. This will load coffee/index.js which loads the coffee scripts in that directory
require('coffee')

// Uncomment this in the future for using tinyMCE through webpack. For now 6/14/21 we're using sprockets
// import { tinyMce } from "../vendor/tinyMCE";
// document.addEventListener("turbolinks:load", function () {    tinyMce(); });

$(document).ready(function() {
    if ($('.alert-dismissible').length) {
        $(".hidden-for-alert").hide();
    }

    $('.alert-dismissible').on('closed.bs.alert', function () {
        $(".hidden-for-alert").show();
    })
});

 window.addInputPrependListener = function (){
    $('.input-group-prepend').on('click', function (e){
        let closestInput = $(this).closest("div.input-group").find("input")
        closestInput.focus();
    })
}